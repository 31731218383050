import axios from 'axios'

class MatchbackRequestService {
  uploadSales (data, onUploadProgress) {
    const formData = new FormData()

    formData.append('file', data.file)

    return axios.post('/sales/upload_matchback?matchback_type=sales&start_date=' + data.startDate + '&end_date=' + data.endDate, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  getMatchbacks (matchbackType) {
    return axios.get(
      '/matchbacks?matchback_type=' + matchbackType,
    )
  }

  deleteMatchback (matchbackId) {
    return axios.delete(
      '/matchbacks/' + matchbackId,
    )
  }
}

export default new MatchbackRequestService()
