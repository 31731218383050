<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Sale DMS Matchbacks"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>

    <template v-if="uploading">
      <v-progress-linear
        v-model="progress"
        color="light-blue"
        height="25"
        reactive
        striped
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </template>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Add New Sales Matchback
          </v-card-title>
          <template>
            <v-file-input
              show-size
              label="Click to select file to run a matchback on. Must be a CSV file."
              accept=".csv"
              @change="selectFile"
            />
          </template>
          <v-row dense>
            <v-col>
              <template>
                <v-col>
                  <v-alert
                    icon="mdi-information"
                    border="top"
                    dense
                    color="blue"
                    elevation="6"
                    :text="true"
                  >
                    Select the date ranges you wish to matchback on
                  </v-alert>
                </v-col>
                <v-row>
                  <v-col
                    class="xs"
                    sm="6"
                    md="4"
                    lg="2"
                    xl="1"
                  >
                    <v-menu
                      v-model="start_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formattedStartDate"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="start_date"
                        @input="start_date_menu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    class="xs"
                    sm="6"
                    md="4"
                    lg="2"
                    xl="1"
                  >
                    <v-menu
                      v-model="end_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formattedEndDate"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="end_date"
                        @input="end_date_menu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right">
              <v-btn
                color="primary"
                :disabled="file === undefined || file == '' || uploading"
                @click="handleFileUpload()"
              >
                Run Matchback
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Matchbacks
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template #item.start_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.end_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.matchback_url="{value}">
              <a
                :href="value"
                download="matchback.csv"
                target="_blank"
              >
                {{ value }}
              </a>
            </template>
            <template #item.source_url="{value}">
              <a
                :href="value"
                download="source.csv"
                target="_blank"
              >
                {{ value }}
              </a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.campaign_summaries="{ item }">
              <router-link
                :to="{ name: 'Matchbacks', params: { campaign_id: item.id } }"
              >
                {{ item.campaign_summaries.length }}
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import MatchbackRequestService from '@/services/MatchbackRequestService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'SaleMatchbacks',
    components: {
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        dialog: false,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Source File', value: 'source_url' },
          { text: 'Matchback File', value: 'matchback_url', align: 'center' },
          { text: 'Start Date', value: 'start_date', align: 'center' },
          { text: 'End Date', value: 'end_date', align: 'center' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        file: '',
        progress: 0,
        uploading: false,
        start_date: null,
        end_date: null,
        start_date_menu: false,
        end_date_menu: false,
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      formattedStartDate () {
        return this.formatDate(this.start_date)
      },
      formattedEndDate () {
        return this.formatDate(this.end_date)
      },
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadMatchbacks()
      },
    },
    created () {
      this.start_date = this.defaultStartDate()
      this.end_date = this.defaultEndDate()
      this.loadMatchbacks()
    },
    methods: {
      loadMatchbacks: function () {
        this.items = []
        this.loading = true
        MatchbackRequestService.getMatchbacks('Sales')
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Matchback?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          MatchbackRequestService.deleteMatchback(item.id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
      handleFileUpload () {
        this.uploading = true
        var data = {
          startDate: this.start_date,
          endDate: this.end_date,
          file: this.file,
        }

        MatchbackRequestService.uploadSales(data, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
          .then((response) => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
            this.loadMatchbacks()
          })
          .catch(() => {
            this.progress = 0
            this.file = undefined
            this.uploading = false
          })
      },
      selectFile (file) {
        this.progress = 0
        this.file = file
      },
    },
  }
</script>
