var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":"Sale DMS Matchbacks"}}),(_vm.error)?[_c('v-banner',{attrs:{"single-line":"","elevation":"10"}},[_c('v-icon',{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v(" mdi-wifi-strength-alert-outline ")]),_c('div',[_vm._v(" There was a problem communicating to the server! "),_c('div',{staticClass:"code"},[_c('strong',[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1)]:_vm._e(),(_vm.uploading)?[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"25","reactive":"","striped":""},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('strong',[_vm._v(_vm._s(_vm.progress)+" %")])])]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Add New Sales Matchback ")]),[_c('v-file-input',{attrs:{"show-size":"","label":"Click to select file to run a matchback on. Must be a CSV file.","accept":".csv"},on:{"change":_vm.selectFile}})],_c('v-row',{attrs:{"dense":""}},[_c('v-col',[[_c('v-col',[_c('v-alert',{attrs:{"icon":"mdi-information","border":"top","dense":"","color":"blue","elevation":"6","text":true}},[_vm._v(" Select the date ranges you wish to matchback on ")])],1),_c('v-row',[_c('v-col',{staticClass:"xs",attrs:{"sm":"6","md":"4","lg":"2","xl":"1"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},on))]}}]),model:{value:(_vm.start_date_menu),callback:function ($$v) {_vm.start_date_menu=$$v},expression:"start_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.start_date_menu = false}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{staticClass:"xs",attrs:{"sm":"6","md":"4","lg":"2","xl":"1"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},on))]}}]),model:{value:(_vm.end_date_menu),callback:function ($$v) {_vm.end_date_menu=$$v},expression:"end_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.end_date_menu = false}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1)]],2)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.file === undefined || _vm.file == '' || _vm.uploading},on:{"click":function($event){return _vm.handleFileUpload()}}},[_vm._v(" Run Matchback ")])],1)],1)],2)],1)],1),(_vm.items.length > 0)?[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" "+_vm._s(_vm.items.length)+" Matchbacks ")])])],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":50,"sort-by":['name']},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.end_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.matchback_url",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value,"download":"matchback.csv","target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.source_url",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value,"download":"source.csv","target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.campaign_summaries",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Matchbacks', params: { campaign_id: item.id } }}},[_vm._v(" "+_vm._s(item.campaign_summaries.length)+" ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Loading "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }